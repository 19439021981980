<template>
  <div class="content md flex-box vertical piece-box">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{pagination.total}}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="评价类型">
          <a-select
              style="width: 180px"
              placeholder="选择评价类型筛选"
              @change="getList"
              :options="types"
              v-model="form.type">
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="评价状态">
          <a-select
              style="width: 180px"
              placeholder="选择状态筛选"
              @change="getList"
              :options="statusList"
              v-model="form.status">
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
      </a-space>
    </div>
    <div class="data-area">
      <a-table
          ref="dataTable"
          :columns="columns"
          row-key="id"
          :data-source="list"
          :loading="loading"
          :pagination="pagination"
          :scroll="{y: h}"
          @change="handleTableChange">
        <template v-slot:name="text">
          <open-data type="userName" :openid="text" />
        </template>
        <template v-slot:action="text, record">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
            <a class="txt-btn danger" @click.stop="delAss(record)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {assTypes, assItemStatus, assListStatus, getAssTypeName, getAssItemStatusText, getAssListStatusText} from "../../common/hr/ass";
import {getHRRole} from "../../common/constant/org";
import {getUserWxId} from "../../common/js/storage";
import {isEmpty} from "../../common/js/tool";

export default {
  name: "Assessment",
  data() {
    return {
      loading: false,
      list: [],
      form: {},
      h: 500,
      columns: [
        {title: '开始时间', dataIndex: 'begin_time'},
        {title: '评价名称', dataIndex: 'programName'},
        {title: '评价类型', dataIndex: 'typeText'},
        {title: '评价状态', dataIndex: 'statusText'},
        {title: '截止时间', dataIndex: 'end_time'},
        {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
      ],
      types: [{key: 0, title: '全部'}].concat(assTypes),
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      },
      role: getHRRole(),
    }
  },
  computed: {
    statusList() {
      let role = this.role;
      let base = [{key: -1, title: '全部'}];
      if(role == 1) {
        return base.concat(assListStatus)
      } else {
        return base.concat(assItemStatus);
      }
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    addProgram() {

    },
    delAss(record) {
      this.$confirm({
          title: '提示',
          content: '删除后数据不可恢复，确定删除此评价数据吗？',
          onOk: () => {
              this.$axios({
                  url: `/admin/evaluate-list/${record.id}`,
                  method: 'DELETE'
              }).then(() => {
                  this.$message.success("已删除");
                  this.getList();
              })
          }
      })
    },
    showDetail(record) {
      if(record.list_id) {
        this.$store.commit("setDetail", {type: 'ass', obj: record})
        this.$router.push({name: "AssessmentDetail", params: {id: record.id}});
      } else {
        this.$router.push({name: "AssProgramDetail", params: {id: record.id}});
      }
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    getList() {
      let role = this.role;
      if(role == 1) {
        this.getAdminList();
      } else {
        this.getPersonList();
      }
    },
    getAdminList() {
      if(this.loading) return;
      let url = `/admin/evaluate-list?sort=-id`;
      let {current, pageSize} = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      let form = this.form;
      if(form.type) {
        url += `&filter[program_type]=${form.type}`
      }
      if(!isEmpty(form.status) && form.status >= 0) {
        url += `&filter[status]=${form.status}`;
      }
      this.loading = true;
      this.$axios(url).then(res => {
        let list = res.data;
        list.forEach(ass => {
          ass.programName = ass.title;
          ass.typeText = getAssTypeName(ass.program_type);
          ass.statusText = getAssListStatusText(ass.status);
        });
        this.list = list;
        if(res.page) {
          this.$set(this.pagination, 'total', res.page.totalCount);
        }
      }).finally(() => this.loading = false);
    },
    getPersonList() {
      let url = `/admin/evaluate-item?sort=-id&expand=list`;
      let {current, pageSize} = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      let form = this.form;
      if(form.type) {
        url += `&filter[program_type]=${form.type}`
      }
      if(!isEmpty(form.status) && form.status >= 0) {
        url += `&filter[status]=${form.status}`;
      }
      url += `&filter[user_id]=${getUserWxId()}`
      this.$axios(url).then(res => {
        let list = res.data;
        list.forEach(ass => {
          ass.programName = ass.list ? ass.list.title : '-';
          ass.typeText = ass.program ? getAssTypeName(ass.program.type) : '-';
          ass.statusText = getAssItemStatusText(ass.status);
        });
        this.list = list;
        if(res.page) {
          this.$set(this.pagination, 'total', res.page.totalCount);
        }
      });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if(dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
  }
}
</script>

<style scoped>

</style>
